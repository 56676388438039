/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Ayer me decidí por intentar instalar Compiz Fusion en mu nuevo Kubuntu y aunque me parecia muy complicado la verdad es que en un momento lo conseguí."), "\n", React.createElement(_components.p, null, "Lo primero que hice fue comprobar la aceleración grafica. Para esto me instalé el mesa-utils."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">aptitude</span> <span class=\"token function\">install</span> mesa-utils</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y comprobé que sorprendetemente tenia la aceleración activada."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\">glxgears</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Si los engranages se mueven sin problemas y en la consola donde empiezan a salir los FPS (Frames Por Segundo) estos suelen ser una cantidad mas o menos estable, entonces es que tienes la aceleración activada. Si no, recominedo utilizar envyng, este programa te instala los drivers privados de tu grafica, a no ser que tengas una Ati anterior a la Radeon 9250 (estos drivers ya no estan soportados por Ati), como es mi caso, es recomendable tenerlos."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">aptitude</span> <span class=\"token function\">install</span> envyng-gtk envyng-core</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "El primero es la interfaz grafica y el segundo la interfaz de modo texto."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> envyng <span class=\"token parameter variable\">-g</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Y se iniciará en programa solo tendrás que seguir los pasos que son muy sencillos y ya tendrás aceleración."), "\n", React.createElement(_components.p, null, "El siguiente paso era instalar ya el Compiz Fusion."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">aptitude</span> <span class=\"token function\">install</span> compizconfig-settings-manager emerald fusion-icon</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Con todo ya instaldado podemos iniciar fusion-icon por consola o buscandolo en el menu y ya tendremos Compiz Fusion con Emerald como gestor de temas, así podremos configurar nuestro escritorio cubo, las ventanas que tiemblan al moverse y muchas cosas mas, por ahora no he tenido problemas, no me enlentece nada, aun tengo mucho que configurar cuando lo tenga listo pondre fotos."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
